// Blocks
import DocumentByLineBlockEdit from './components/Blocks/DocumentByLine/Edit';
import DocumentByLineBlockView from './components/Blocks/DocumentByLine/View';

// Icons
import pencilSVG from '@plone/volto/icons/pencil.svg';

const applyConfig = (config) => {
  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['pt-br'],
    defaultLanguage: 'pt-br',
    apiExpanders: [
      ...config.settings.apiExpanders,
      {
        match: '',
        GET_CONTENT: [
          'breadcrumbs',
          'navigation',
          'actions',
          'authors',
          'types',
        ],
        querystring: {
          'expand.navigation.depth': 2,
        },
      },
    ],
    image_crop_aspect_ratios: [
      {
        label: '16:9',
        ratio: 16 / 9,
      },
      {
        label: '4:3',
        ratio: 4 / 3,
      },
      {
        label: '1:1',
        ratio: 1,
      },
    ],
    socialNetworks: [
      {
        id: 'twitter',
        href: 'https://twitter.com/diracom_',
      },
      {
        id: 'instagram',
        href: 'https://instagram.com/diracom_',
      },
    ],
  };
  const localBlocks = {
    documentByline: {
      id: 'documentByline',
      title: 'Assinatura',
      group: 'text',
      icon: pencilSVG,
      view: DocumentByLineBlockView,
      edit: DocumentByLineBlockEdit,
      restricted: false,
      mostUsed: false,
      sidebarTab: true,
      blockHasOwnFocusManagement: false,
    },
  };
  config.blocks.blocksConfig = {
    ...config.blocks.blocksConfig,
    ...localBlocks,
  };

  // Grid Block
  // Remove old Grid Block
  config.blocks.blocksConfig.__grid.restricted = true;
  config.blocks.blocksConfig.gridBlock.allowedBlocks = [
    ...config.blocks.blocksConfig.gridBlock.allowedBlocks,
    'facebookBlock',
    'instagramBlock',
    'tweetBlock',
    'video',
  ];
  config.blocks.blocksConfig.gridBlock.blocksConfig = {
    ...config.blocks.blocksConfig.gridBlock.blocksConfig,
    facebookBlock: config.blocks.blocksConfig.facebookBlock,
    instagramBlock: config.blocks.blocksConfig.instagramBlock,
    tweetBlock: config.blocks.blocksConfig.tweetBlock,
  };
  // Twitter
  config.blocks.blocksConfig.tweetBlock = {
    ...config.blocks.blocksConfig.tweetBlock,
    defaultLanguage: 'pt',
  };
  return config;
};

export default applyConfig;
