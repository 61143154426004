export const blockSchema = (props) => {
  return {
    title: 'Assinatura',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['showPublished', 'showModified', 'showAuthor'],
      },
    ],
    properties: {
      showPublished: {
        title: 'Exibir data de publicação?',
        type: 'boolean',
        default: true,
      },
      showModified: {
        title: 'Exibir última modificação?',
        type: 'boolean',
        default: true,
      },
      showAuthor: {
        title: 'Exibir autor?',
        type: 'boolean',
        default: false,
      },
    },

    required: ['showPublished', 'showModified', 'showAuthor'],
  };
};
