import React from 'react';
import DocumentByLine from '../../DocumentByLine/DocumentByLine';

const View = (props) => {
  const { properties, data } = props;
  let { showPublished, showModified, showAuthor } = data;
  showPublished = showPublished === undefined ? true : showPublished;
  showModified = showModified === undefined ? true : showModified;
  showAuthor = showAuthor === undefined ? false : showAuthor;
  return (
    <div className="documentByLine block">
      <DocumentByLine
        content={properties}
        showPublished={showPublished}
        showModified={showModified}
        showAuthor={showAuthor}
      />
    </div>
  );
};

export default View;
