/**
 * DocumentByLine component.
 * @module components/DocumentByLine/ServiceView
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import { FormattedDate } from '@plone/volto/components';
import { FormattedMessage, injectIntl } from 'react-intl';

const authorsInfo = (content) => {
  const creators = content.creators || [];
  let authors = content['@components']?.['authors'] || [];
  if (authors.length === 0) {
    for (const creator of creators) {
      authors.push({
        fullname: creator,
      });
    }
  }
  return authors;
};

const DocumentByLine = ({
  content,
  showModified,
  showPublished,
  showAuthor,
}) => {
  const authors = authorsInfo(content);
  const effective = content.effective;
  const modified = content.modified;
  const displayAuthor = showAuthor && authors;
  const displayModified = showModified && modified;
  const displayPublished = showPublished && effective;
  return (
    <Container className={'documentByLine'}>
      {displayPublished && (
        <Container className={'info effective'}>
          <FormattedMessage id="effective" defaultMessage="Publicado" />
          : <FormattedDate date={effective} />
        </Container>
      )}
      {displayModified && (
        <Container className={'info modified'}>
          <FormattedMessage
            id="last_modified"
            defaultMessage="Última Modificação"
          />
          : <FormattedDate date={modified} />
        </Container>
      )}
      {displayAuthor && (
        <Container className={'info author'}>
          <FormattedMessage id="author" defaultMessage="Por" />:{' '}
          {authors &&
            authors.map((author, i) => (
              <span className={'name'} key={i}>
                {author.fullname}
              </span>
            ))}
        </Container>
      )}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
DocumentByLine.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
};

export default injectIntl(DocumentByLine);
